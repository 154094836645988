import React from 'react';
import Site from '../../components/Site';

const Rigtalk = () => {
    const data = {
        id: 13,
        id_en: 360,
        context: 'rigtalk',
        title: 'Rigtalk',
        title_en: 'Rigtalk',
        div_class: 'logo-rigtalk',
        img: '/assets/img/websites/rigtalk-logo@2x.png',
        img_w: 441,
        img_h: 80,
        url: 'https://www.rig-talk.com/forum/',
        flag_fr: false,
        flag_en: false,
        flag_us: true,
    }
    return (
        <div>
            <Site data={data} />
        </div>
    );
};
export default Rigtalk;