import React from 'react';
import Site from '../../components/Site';

const Homerecording = () => {
    const data = {
        id: 17,
        id_en: 364,
        context: 'homerec',
        title: 'HomeRecording',
        title_en: 'HomeRecording',
        div_class: 'logo-homerecording',
        img: '/assets/img/websites/homerecording-logo.png',
        img_w: 216,
        img_h: 53,
        url: 'https://homerecording.com/bbs/',
        flag_fr: false,
        flag_en: false,
        flag_us: true,
    }
    return (
        <div>
            <Site data={data} />
        </div>
    );
};

export default Homerecording;